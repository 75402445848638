.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* CSS */
.button-52 {
  font-size: 1.5rem;
  font-family: Bebas;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 2px solid white;
  color: #4a4a4a;
  /* color: black; */
  cursor: pointer;
  position: relative;
  /* z-index: 10; */
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-52:after {
  content: "";
  background-color: #89cff0;
  background: #F3C47E;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.button-52:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .button-52 {
    padding: 13px 50px 13px;
  }
}



/* input:focus, input:active {
  border-color: "red" !important;
  font-size: 4rem;
} */

.inputBox {
  position: relative;
  width: 100%;
}

.inputBox input, .inputBox textarea{
  width: 100%;
  height: 50px;
  padding: 10px;
  border: 1px solid #89CFF0;
  background: #011936;
  outline: none;
  color: white;
  font-size: 1.5rem;
}

.inputBox span {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1em;
  color: #444;
  pointer-events: none;
  color: white;
  text-transform: uppercase;
  transition: all .5s ease-in-out;
}

.inputBox input:focus ~ span , .inputBox textarea:focus ~ span{
  color: #89CFF0;
  transform: translateX(10px) translateY(-7px);
  background: #011936;
  padding: 0 1em;
  border-left: 1px solid #89CFF0;
  border-right: 1px solid #89CFF0;
}

.inputBox input:valid ~ span , .inputBox textarea:valid ~ span{
  color: #89CFF0;
  transform: translateX(10px) translateY(-7px);
  background: #011936;
  padding: 0 1em;
  border-left: 1px solid #89CFF0;
  border-right: 1px solid #89CFF0;
}
/* 
.inputBox input:blank ~ span , .inputBox textarea:blank ~ span{
  color: #89CFF0;
  transform: translateX(10px) translateY(-7px);
  background: #011936;
  padding: 0 1em;
  border-left: 1px solid #89CFF0;
  border-right: 1px solid #89CFF0;
}

.inputBox input:invalid ~ span , .inputBox textarea:invalid ~ span{
  color: red;
  transform: translateX(10px) translateY(-7px);
  background: #011936;
  padding: 0 1em;
  border-left: 1px solid red;
  border-right: 1px solid red;
} */

.inputBox textarea {
  resize: none;
}

